import { Observable } from "rxjs";
import { RolPermissionInterface } from "@roles-permissions/ports/interfaces";
import { ActionInterface, ModulesInterface, RespInterface, RespListInterface } from "@common/ports/interfaces";

export abstract class RolPermissionAbstraction {
  abstract list(query: string): Observable<RespListInterface<RolPermissionInterface>>;
  abstract listModules(): Observable<RespListInterface<ModulesInterface>>;
  abstract create(rolPermission: RolPermissionInterface): Observable<RespInterface<RolPermissionInterface>>;
  abstract update(rolPermission: RolPermissionInterface): Observable<RespInterface<string>>;
  abstract updateAction(action: ActionInterface): Observable<RespInterface<RolPermissionInterface>>;
  abstract read(code: string): Observable<RespInterface<RolPermissionInterface>>;
  abstract delete(_id: string): Observable<RespInterface<RolPermissionInterface>>;
}
